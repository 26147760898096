import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
//outsource
import { Row, Col, Modal, Button, Checkbox, Form } from "antd";
// import { BRAND_NAME } from "../../../../config/config";
import { GENERAL_INFO } from "../../../../store/data/data";

//components
import DialogBookingAlert from "./dialog_booking_alert";
import DialogBookingAlert2 from "./dialog_booking_alert2";
// import BookingStep from "./component/booking_step";
import SelectService from "./component/select_service";
import SelectedServices from "./component/selected_service";
import SelectedDateTime from "./component/selected_datetime";
import SelectFullDate from "./component/select_full_date";
import SelectInformation from "./component/select_info";
import DialogCustomerPolicy from "./dialog_customer_policy";

//redux
import {
  selectServiceSelector,
  fullDateSelector,
  stepSelector,
} from "../../../../store/service/service.selector";
import {
  addBookingInfo,
  selectFullDateService,
  selectService,
  selectStepService,
  // uploadRequestImage,
  sendMessageToTelegram,
} from "../../../../store/service/service.action";
import { generateMessage } from "../util/general.function";
import { branchDetailBySlugSelector } from "../../../../store/branch/branch.selector";

const stepTitles = [
  "Select Services (1/3)",
  "Day & Time (2/3)",
  "Customer Information (3/3)",
];

const dateFormat = "DD/MM/YYYY";
const dateTimeFormat = "DD/MM/YYYY hh:mm A";
const timeFormat = "hh:mm A";

const DialogBookingAdd = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen, setOpenCancel, group, list } = props;
  const [form] = Form.useForm();
  // -------------------------- STATE --------------------------
  const [uri, setUri] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openSecondAlert, setOpenSecondAlert] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [full, setFull] = useState({
    date: moment().add(3, "days").format(dateFormat),
    time: moment().format(timeFormat),
  });
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();

  const step = useSelector(stepSelector);
  const selectedService = useSelector(selectServiceSelector);
  const selectedDatetime = useSelector(fullDateSelector);
  const branchDetail = useSelector(branchDetailBySlugSelector);

  // -------------------------- FUNCTION -----------------------
  const handleConfirmAlert = () => {
    setOpenAlert(false);
    setDisabled(true);
    setFull({
      date: moment().add(3, "days").format("DD/MM/YYYY"),
      time: "09:15 AM",
    });
    selectStepService(dispatch, 1);
  };

  const handleConfirmSecondAlert = () => {
    setOpenSecondAlert(false);
    setDisabled(true);
    setFull({
      date: moment().add(3, "days").format("DD/MM/YYYY"),
      time: "09:15 AM",
    });
    selectStepService(dispatch, 1);
  };

  const handleBack = () => {
    const previous = step >= 1 ? step - 1 : step;
    selectStepService(dispatch, previous);
    setDisabled(true);
  };

  const handleNext = () => {
    const next = step <= 1 ? step + 1 : step;
    selectStepService(dispatch, next);
    document.getElementsByClassName("ant-modal-wrap")[0].scrollTo((0, 0), 100);
  };

  const handleClose = () => {
    setOpen(false);
    setDisabled(true);
  };

  const handleCheck = (e) => {
    const checked = e.target.checked;
    checked ? setDisabled(false) : setDisabled(true);
  };

  const onFinish = (values) => {
    setLoading(true);
    const service = selectedService?.map((item) => {
      const name = item?.name;
      const price = item?.price;
      const amount = item?.amount;
      return {
        name,
        price,
        amount,
      };
    });

    const time = selectedDatetime;
    const fullDate = moment(time).format("DD/MM/YYYY hh:mm A");
    let sample = "";

    if (service?.length > 0 && time) {
      const dataRaw = {
        ...values,
        guests: 1,
        gender: "",
        services: service,
        fullDate,
        salon: branchDetail?.name || "Manukau",
      };

      addRecord({ data: dataRaw });
      // dispatch(
      //   uploadRequestImage({ uri }, (url) => {
      //     sample = url;
      //     const dataRaw = {
      //       ...values,
      //       guests: 1,
      //       gender: "",
      //       services: service,
      //       fullDate,
      //       sample,
      //     };

      //     addRecord({ data: dataRaw });
      //   })
      // );
    }
  };

  const addRecord = useCallback(
    ({ data }) => {
      console.log({ data });
      const message = generateMessage({ data });
      dispatch(
        addBookingInfo(
          { data },
          dispatch(
            sendMessageToTelegram({ message }, (res) => {
              // console.log({ res });
              setLoading(false);
              setOpen(false);
              setSelected([]);
              setDisabled(true);
              setFull({
                date: moment().add(2, "days").format(dateFormat),
                time: moment().format(timeFormat),
              });
              setUri("");
              form.resetFields();
              if (res?.data?.status === 200) {
                window.location.href = "https://tbbsalon.co.nz/thank-you-2/";
              }
            })
          )
        )
      );
    },

    [form, dispatch]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
  };

  // -------------------------- EFFECT --------------------------
  //Select Date
  useEffect(() => {
    if (full?.date !== "" && full?.time !== "") {
      const datestring = `${full?.date} ${full?.time}`;
      const fullDate = moment(datestring, dateTimeFormat).toDate();
      selectFullDateService(dispatch, fullDate);
    } else {
      const today = moment().toDate();
      selectFullDateService(dispatch, today);
    }

    const today = moment().format(dateFormat);
    const tmr = moment().add(1, "day").format(dateFormat);
    const now = moment();
    const target = moment(`${today} 04:00 PM`, dateTimeFormat);
    const isDiff = target.diff(now, "minutes");

    if (full?.date !== "" && full?.date === today) {
      setOpenAlert(true);
    }

    if (full?.date !== "" && full?.date === tmr && isDiff <= 0) {
      setOpenSecondAlert(true);
    }
  }, [full]);

  //SeLect services
  useEffect(() => {
    if (selected) {
      selectService(dispatch, selected);
    }
  }, [dispatch, selected]);

  // -------------------------- RENDER --------------------------
  const renderModalContent = () => {
    return (
      <Row>
        <Col span={24}>{renderBody()}</Col>
      </Row>
    );
  };

  // ####-------- BODY --------- ####//
  const renderBody = () => {
    return (
      <Row gutter={[20]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          {step === 0 ? renderNote2() : null}
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
          {renderSelectBooking()}
        </Col>
        <Col align="center" xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
          {renderServiceSelected()}
          {step === 0 ? renderNote() : null}
          {step !== 0 ? renderDateTime() : null}
          {step === 2 ? renderConfirm() : null}
        </Col>
      </Row>
    );
  };

  const renderNote = () => {
    return (
      <>
        <div className="note-booking">
          Please make one booking per person. If there’s more than one person,
          make separate bookings for each person in your group. When booking for
          more than 3 people please call us at {GENERAL_INFO?.phone}
        </div>
      </>
    );
  };

  const renderNote2 = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <div style={{ color: "red", fontSize: 13 }}>
          (*) The price may vary depending on the length of the products, the
          volume of work, and the level of customization required
        </div>
      </div>
    );
  };

  const renderSelectBooking = () => {
    switch (step) {
      case 0:
        return (
          <SelectService
            group={group}
            list={list}
            selected={selected}
            setSelected={setSelected}
            selectedService={selectedService}
          />
        );
      case 1:
        return <SelectFullDate full={full} setFull={setFull} />;

      default:
        return (
          <SelectInformation
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            setUri={setUri}
            uri={uri}
          />
        );
    }
  };

  const renderServiceSelected = () => {
    return (
      <SelectedServices
        selectedService={selectedService}
        setSelected={setSelected}
        selected={selected}
      />
    );
  };

  const renderDateTime = () => {
    return <SelectedDateTime selectedDatetime={selectedDatetime} />;
  };

  const renderConfirm = () => {
    return (
      <Row style={{ marginTop: "1rem" }} gutter={[20]}>
        <Col span={2}>
          <Checkbox onChange={(e) => handleCheck(e)} />
        </Col>
        <Col align="left" span={20}>
          I have read and agree to{" "}
          <a
            target="_blank"
            className="btn-link"
            onClick={() => setOpenCustomer(true)}
          >
            Customer policy
          </a>{" "}
          &{" "}
          <a
            target="_blank"
            className="btn-link"
            onClick={() => setOpenCancel(true)}
          >
            Booking policy
          </a>
          .
        </Col>
      </Row>
    );
  };

  // ####-------- BODY --------- ####//

  const renderFooter = () => {
    return (
      <Row gutter={[20]}>
        <Col align="left" span={12}>
          {step === 0 ? null : renderButtonBack()}
        </Col>
        <Col span={12}>
          {step === 2 ? renderButtonConfirm() : renderButtonContinue()}
        </Col>
      </Row>
    );
  };

  const renderButtonBack = () => {
    return (
      <Button
        onClick={handleBack}
        className="btn-booking-cancel"
        type="default"
        loading={loading}
      >
        Back
      </Button>
    );
  };

  const renderButtonContinue = () => {
    const sumAmount =
      selectedService
        ?.map((item) => {
          return item?.amount;
        })
        .reduce((a, b) => a + b, 0) || 0;
    const validStepOne =
      (step === 0 && selectedService?.length === 0) || sumAmount === 0;

    const now = moment();
    const checkDate = moment(selectedDatetime).isAfter(now);
    const validStepTwo = step === 1 && !checkDate;

    const validStep = validStepOne || validStepTwo;
    // console.log("validStepTwo", validStep);

    return (
      <Button
        onClick={handleNext}
        className={
          validStep ? "btn-booking-continue-disabled" : "btn-booking-continue"
        }
        type="primary"
        loading={loading}
        disabled={validStep}
      >
        Continue
      </Button>
    );
  };

  const renderButtonConfirm = () => {
    return (
      <Button
        form="modal"
        htmlType="submit"
        className={
          disabled ? "btn-booking-confirm-disabled" : "btn-booking-confirm"
        }
        type="primary"
        disabled={disabled}
        loading={loading}
      >
        Confirm Booking
      </Button>
    );
  };

  const renderAlertDialog = () => {
    return (
      <>
        <DialogBookingAlert
          open={openAlert}
          setOpen={setOpenAlert}
          handleConfirmAlert={handleConfirmAlert}
        />
        <DialogBookingAlert2
          open={openSecondAlert}
          setOpen={setOpenSecondAlert}
          handleConfirmAlert={handleConfirmSecondAlert}
        />
      </>
    );
  };

  const renderDialogCustomerPolicy = () => {
    return (
      <DialogCustomerPolicy open={openCustomer} setOpen={setOpenCustomer} />
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <>
      {renderAlertDialog()}
      {renderDialogCustomerPolicy()}
      <Modal
        className="modal-booking"
        open={open}
        title={stepTitles[step]}
        width={900}
        footer={renderFooter()}
        onCancel={handleClose}
      >
        <Form
          id="modal"
          type="basic"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {renderModalContent()}
        </Form>
      </Modal>
    </>
  );
};

export default DialogBookingAdd;
