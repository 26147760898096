import React from "react";
//outsource
import { Col, Input, Form } from "antd";

//components
const { TextArea } = Input;

const BookingNote = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      label="Let us know any specific requests or preferences"
      name="note"
      initialValue={""}
    >
      <TextArea
        style={{ resize: "none" }}
        rows={4}
        placeholder="Tell us more"
      ></TextArea>
    </Form.Item>
  );
};

export default BookingNote;
