import React from "react";
//outsource
import { Row, Col, Radio, Space, Calendar } from "antd";
import moment from "moment";
import XmasIcon from "../../../../../asset/img/xmas.png";
//string
import {
  NORMAL_DAYS26,
  NORMAL_SARTUDAY,
  NORMAL_SUNDAY,
  XMAS_DAILY,
  XMAS_SUNDAY,
} from "../../../../../store/data/booking";

//holidays
const holiday = {
  xmas: "25/12/2023",
  newyear: "01/01/2024",
  anzac: "25/04/2024",
};

//xmas days

const prexmas = [
  "18/12/2023",
  "19/12/2023",
  "20/12/2023",
  "21/12/2023",
  "22/12/2023",
  "23/12/2023",
  "24/12/2023",
];

const specialday = ["24/12/2023"];

const xmasday = ["25/12/2023"];

const SelectFullDate = ({ full, setFull }) => {
  // -------------------------- VAR --------------------------
  const time = full?.time;
  const date = full?.date && moment(full?.date, "DD/MM/YYYY");
  const dateString = date && date.format("DD/MM/YYYY");

  //detect sunday
  const detected = date.format("dd");
  console.log({ detected });

  const dataSource =
    detected === "Su"
      ? NORMAL_SUNDAY
      : detected === "Sa"
      ? NORMAL_SARTUDAY
      : NORMAL_DAYS26;

  //detect xmas
  const xmasDetected = prexmas?.includes(dateString) || false;
  const dataSourceXmas = detected === "Su" ? XMAS_SUNDAY : XMAS_DAILY;

  const targetData = xmasDetected ? dataSourceXmas : dataSource;

  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION ------------------------
  const onSelect = (value) => {
    const date = moment(value.toDate()).format("DD/MM/YYYY");
    setFull((full) => {
      return {
        ...full,
        date,
      };
    });
  };

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    // setTime(e.target.value);
    setFull((full) => {
      return {
        ...full,
        time: e.target.value,
      };
    });
  };
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  const renderArrivalTime = () => {
    return (
      <Row style={{ marginTop: "2rem" }} gutter={[5]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h2 style={{ fontSize: "20px" }} className="title-time">
            Arrival Time:
          </h2>
        </Col>
        {renderNormalDays()}
      </Row>
    );
  };

  const renderNormalDays = () => {
    return (
      <>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          {renderMorningSunday()}
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          {renderAfternoonSunday()}
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          {renderEveningSunday()}
        </Col>
      </>
    );
  };

  const renderMorningSunday = () => {
    return (
      <Radio.Group onChange={onChange} value={time}>
        <Space direction="vertical">
          {targetData.MORNING?.map((item) => {
            const time = `${dateString} ${item}`;
            const validTime = moment(time, "DD/MM/YYYY hh:mm A");
            const valid = validTime.isBefore(moment());
            return (
              <Radio disabled={valid} key={item} value={item}>
                {item}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    );
  };

  const renderAfternoonSunday = () => {
    return (
      <Radio.Group onChange={onChange} value={time}>
        <Space direction="vertical">
          {targetData.AFTERNOON?.map((item) => {
            const time = `${dateString} ${item}`;
            const validTime = moment(time, "DD/MM/YYYY hh:mm A");
            const valid = validTime.isBefore(moment());
            return (
              <Radio disabled={valid} key={item} value={item}>
                {item}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    );
  };

  const renderEveningSunday = () => {
    return (
      <Radio.Group onChange={onChange} value={time}>
        <Space direction="vertical">
          {targetData.EVENING?.map((item) => {
            const time = `${dateString} ${item}`;
            const validTime = moment(time, "DD/MM/YYYY hh:mm A");
            const valid = validTime.isBefore(moment());
            return (
              <Radio disabled={valid} key={item} value={item}>
                {item}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    );
  };

  const getCellRender = (value) => {
    const cellString = value?.format("DD/MM/YYYY");
    const validsCell = xmasday?.includes(cellString) || false;
    if (validsCell) {
      return <img src={XmasIcon} style={{ width: 30 }} alt="icon" />;
    }
  };

  // -------------------------- MAIN --------------------------
  return (
    <>
      <Calendar
        className="calender-booking"
        fullscreen={false}
        onSelect={onSelect}
        value={date}
        dateCellRender={getCellRender}
        disabledDate={(current) => {
          // disabled normal days
          const now = moment();
          const prev = moment().subtract(1, "days");
          const max = moment(
            NORMAL_DAYS26.AFTERNOON[NORMAL_DAYS26.AFTERNOON?.length],
            "hh:mm A"
          );

          //disabled holiday
          const dateString = current?.format("DD/MM/YYYY");
          const validsHoliday = dateString === holiday?.anzac || false;

          const validDisabled = now.isAfter(max)
            ? current.isBefore(now)
            : current.isBefore(prev);

          return validDisabled || validsHoliday;
        }}
      />
      {renderArrivalTime()}
    </>
  );
};

export default SelectFullDate;
