import { initializeApp } from "firebase/app";
export const BRAND_NAME = "The Beauty Boutique"; // Brand Name
// Thông tin tài khoản hệ thống
export const SANDBOX = true;

export const firebaseConfig = {
  apiKey: "AIzaSyDqky_j3p8ygRvC_84n2_Uau4GxbU9DGZA",
  authDomain: "tbbsalon-2baff.firebaseapp.com",
  projectId: "tbbsalon-2baff",
  storageBucket: "tbbsalon-2baff.appspot.com",
  messagingSenderId: "430839660973",
  appId: "1:430839660973:web:ac63fd92d4b747e99de124",
  measurementId: "G-N1C4WL9TVH",
};

export const reCAPTCHA = "6LdINAUqAAAAAA1hEF4fn6RRnb8bGtO7a-2CCoyl";
export const app = initializeApp(firebaseConfig);

// ========================= App String ========================= //
export const VERSION = "v0.1.1";
export const WEB_DOMAIN = "https://booking.tbbsalon.co.nz";
