export const UPDATE_DATA = [
  {
    id: "2mevNc6NkSQSZ5WmNdZi",
    regularPrice: 45,
    price: 45,
  },
  {
    id: "EWD6GOK9GklCyw7UTEiz",
    regularPrice: 50,
    price: 50,
  },
  {
    id: "2u2DED0ZUZuBoYdtViWj",
    regularPrice: 100,
    price: 100,
  },
  {
    id: "NUAVNkjqFuDYNnycqof1",
    regularPrice: 20,
    price: 20,
  },
  {
    id: "aZGQ1KCpAzQtx3sv9NdE",
    regularPrice: 20,
    price: 20,
  },
  {
    id: "P28VuBO4xCjCmJdJ23r4",
    regularPrice: 15,
    price: 15,
  },
  {
    id: "oM9nNdfwDBZxiVl6wOcm",
    regularPrice: 30,
    price: 30,
  },
  {
    id: "Ug2fAhLd8u1J4X7hgQzK",
    regularPrice: 35,
    price: 35,
  },
  {
    id: "QDZHYl850sX9ipROORou",
    regularPrice: 40,
    price: 40,
  },
  {
    id: "1MpWw9IV5D5KWruakObq",
    regularPrice: 45,
    price: 45,
  },
];

export const CREATE_DATA = [
  {
    from: true,
    group: "fakAGTcQGTU10n4H8S8A",
    name: "Gel-X",
    order: 5,
    price: 70,
    regularPrice: 70,
  },
  {
    from: true,
    group: "fakAGTcQGTU10n4H8S8A",
    name: "Extra long tips",
    order: 6,
    price: 10,
    regularPrice: 10,
  },
  {
    from: false,
    group: "tNhqi914Vkfwg5PALieh",
    name: "Acrylic - Toe Nails (per toe)",
    order: 8,
    price: 10,
    regularPrice: 10,
  },
];
