import React from "react";
import Router from "./screen/00_Nav/router";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "#DACEC2",
    // secondaryColor: "#DACEC2",
    textColor: "#737373",
  },
});

const App = () => {
  return (
    <ConfigProvider>
      <Router />
    </ConfigProvider>
  );
};

export default App;
