import { TYPES } from "./service.type";

const initState = {
  group: null,
  service: null,
  select: null,
  fulldate: null,
  step: 0,
};

const serviceReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.GET_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        group: payload,
      };
    case TYPES.GET_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        service: payload,
      };

    case TYPES.SELECT_SERVICE_SUCCESS:
      return {
        ...state,
        select: payload,
      };
    case TYPES.SELECT_SERVICE_DATETIME_SUCCESS:
      return {
        ...state,
        fulldate: payload,
      };
    case TYPES.SELECT_SERVICE_STEP_SUCCESS:
      return {
        ...state,
        step: payload,
      };

    default:
      return state;
  }
};

export default serviceReducer;
