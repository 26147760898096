import React from "react";
//outsource
import { Col, Input, Form } from "antd";

//components

const BookingEmail = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="email"
      label="Enter your Email"
      rules={[
        {
          required: true,
          message: "Please input email",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          type: "email",
          message: "Wrong email format",
        },
      ]}
    >
      <Input placeholder="Email" />
    </Form.Item>
  );
};

export default BookingEmail;
