import React from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";

//redux
import { selectStepService } from "../../../../store/service/service.action";

//outsource
import { Modal, Button } from "antd";

const DialogBookingSuccess = ({ open, setOpenSuccess }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------

  const handleDone = () => {
    setOpenSuccess(false);
    selectStepService(dispatch, 0);
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  const renderButtonFooter = () => {
    return (
      <Button className="btn-booking-done" onClick={handleDone} type="primary">
        Done
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Modal
      open={open}
      title={null}
      closable={false}
      footer={renderButtonFooter()}
    >
      <div style={{ textAlign: "center", padding: "0px 30px" }}>
        <h1 className="success-booking-title">Thank You</h1>
        <FaCheck size={60} color={"green"} />
        <p>
          We are processing your booking request. Please wait for our
          confirmation via phone or email
        </p>
      </div>
    </Modal>
  );
};

export default DialogBookingSuccess;
