import React from "react";
import { Col, Row, Button, Typography, List } from "antd";

import { useDispatch, useSelector } from "react-redux";
//components
import HomeLocation from "./component/home_location";

import { allBranchesSelector } from "../../../store/branch/branch.selector";
import { addBranch } from "../../../store/branch/branch.action";

const { Title } = Typography;

const HomeBanner = ({ mobile }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // const dispatch = useDispatch();

  const allBranches = useSelector(allBranchesSelector);
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  const renderSummaryStore = () => {
    return (
      <div style={{ fontSize: mobile ? "small" : "16px" }}>
        <p className="home-info" style={{ marginRight: "1rem" }}>
          Please select location
        </p>
        {/* <List
          className="location-list"
          renderItem={(item, index) => (
            <List.Item
              key={item?.slug}
              className={index % 2 !== 0 ? "align-start" : "align-end"}
              style={{ display: "flex" }}
            >
              {renderItem(item, index)}
            </List.Item>
          )}
          dataSource={allBranches || []}
          rowKey={"slug"}
          grid={{
            gutter: [8, 8], // Add some small gutter if needed, or keep [0, 0]
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          }}
        /> */}
        <div
          className="location-list"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {allBranches?.map((item, index) => (
            <div
              key={item?.slug}
              className={index % 2 !== 0 ? "align-start" : "align-end"}
            >
              <HomeLocation item={item} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderItem = (item) => {
    return <HomeLocation item={item} />;
  };

  // -------------------------- MAIN --------------------------
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Row className="banner" gutter={[0, 20]}>
        <Col
          className="booking-info"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          {renderSummaryStore()}
        </Col>
      </Row>
    </Col>
  );
};

export default HomeBanner;
