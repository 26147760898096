import React from "react";

//redux
import { GENERAL_INFO } from "../../../../store/data/data";
import AlertPhone from "../../../../asset/img/alert-removebg.png";
//outsource
import {  Modal, Button } from "antd";

const DialogBookingAlert2 = ({ open, handleConfirmAlert }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  const renderButtonFooter = () => {
    return (
      <Button
        className="btn-booking-done"
        onClick={handleConfirmAlert}
        type="primary"
      >
        Ok
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Modal
      open={open}
      title={null}
      closable={false}
      footer={renderButtonFooter()}
    >
      <div style={{ textAlign: "center", padding: "0px 30px" }}>
        <img
          src={AlertPhone}
          style={{ width: 200, marginBottom: 20 }}
          alt="alert"
        />
        <p style={{ fontWeight: "bold", fontSize: 18 }}>
          Sorry! There isn’t enough time for us to process your booking request
          for tomorrow. Please walk in or call the salon directly at{" "}
          {GENERAL_INFO?.phone}.
        </p>
      </div>
    </Modal>
  );
};

export default DialogBookingAlert2;
