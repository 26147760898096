import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import languageReducer from "./language/language.reducer";
import serviceReducer from "./service/service.reducer";
import branchReducer from "./branch/branch.reducer";

const rootReducer = combineReducers({
  authReducer,
  languageReducer,
  serviceReducer,
  branchReducer,
});

export default rootReducer;
