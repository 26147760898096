// import React from "react";
// import { Route } from "react-router-dom";
// import { URL } from "./url";
// import NavSideBar from "./nav/nav_sidebar";
// import RouteDashboard from "./route/route_home";
// import RouteUsers from "./route/route_users";

const RoutePrivate = () => {
  // -------------------------- STATE --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  // return (
  //   <Route exact path={URL.home} element={<NavSideBar />}>
  //     {/* {RouteDashboard()}
  //     {RouteUsers()} */}
  //   </Route>
  // );
  return null;
};

export default RoutePrivate;
