export const generateMessage = ({ data }) => {
  const { services, firstName, lastName, phone, email, fullDate, note, salon } =
    data;

  const formattedBooking = [];
  let totalPrice = 0;
  let totalServices = 0;
  for (let i = 0; i < services?.length; i++) {
    const service = services[i];
    const formattedService = `
  Service: ${service?.name}
  Quantity: x${service?.amount}
  Total: $${(service?.amount * service?.price).toFixed(2)}
      `;
    formattedBooking.push(formattedService);
    totalPrice += service?.amount * service?.price;
    totalServices += service?.amount;
  }

  const formattedNote = `Note(s): ${note}`;

  const formattedClient = `
-- Client Contact -- 
  Name: ${firstName} ${lastName} 
  Phone: ${phone}
  Email: ${email}
  ${note !== "" ? formattedNote : ""}\n`;

  const message = `
           
      Ms. ${firstName} ${lastName} has just made a booking for the ${salon} salon\n
-- Booking Details --
${formattedBooking.join("")}
Total: ${totalServices} service(s) - $${totalPrice.toFixed(2)}
Booking On: ${fullDate} 
        ${formattedClient}
              
      `;

  return message;
};

export const transformedFormat = (data) => {
  const newData = data?.map((item) => {
    return {
      order: item[0],
      name: item[1],
      price: item[2],
      regularPrice: item[2],
      group: item[3],
      from: item[4],
    };
  });

  return newData;
};

export const getLocationBooking = (record) => {
  const address = !!record?.address ? `${record?.address}` : "";
  const centre = !!record?.centre ? `${record?.centre}` : "";
  const label = `${address}, ${centre}`;
  return label;
};

export const getAddressBooking = (record) => {
  const address = !!record?.address ? `${record?.address}` : "";
  const centre = !!record?.centre ? `${record?.centre}` : "";
  const city = !!record?.city ? `${record?.city}` : "";
  const label = `${address}, ${centre}, ${city}`;
  return label;
};

export const getPhoneLink = (phoneNumber) => {
  // Remove any non-digit characters
  let digits = phoneNumber?.replace(/\D/g, "");

  // Ensure it's the correct length
  if (digits.length === 9) {
    // Insert dashes at the correct positions
    return `tel:${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else {
    throw new Error("Unexpected phone number format");
  }
};
