import React, { useState, useEffect } from "react";
import { Layout, Grid, Row } from "antd";
// component

//redux

// strings
import { GENERAL_INFO, MAP } from "../../../store/data/data";
import "./style.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBranchBySlug } from "../../../store/branch/branch.action";
import BranchBanner from "./component/branch_banner";
import BranchGeneralInfo from "./component/branch_general_info";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const BranchScreen = () => {
  // -------------------------- VAR --------------------------

  const screens = useBreakpoint();
  const { branchSlug } = useParams();
  // -------------------------- STATE --------------------------

  const [mobile, setMobile] = useState(false);

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION -----------------------

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(getBranchBySlug({ slug: branchSlug }));
  }, [branchSlug, dispatch]);

  useEffect(() => {
    if (screens?.xs) {
      setMobile(true);
    }
    if (screens?.sm) {
      setMobile(true);
    }
    if (screens?.md) {
      setMobile(true);
    }
    if (screens?.lg) {
      setMobile(false);
    }
    if (screens?.xl) {
      setMobile(false);
    }
    if (screens?.xxl) {
      setMobile(false);
    }
  }, [screens]);

  // -------------------------- RENDER --------------------------

  // Render Section Banner
  const renderSectionBanner = () => {
    return <BranchBanner data={GENERAL_INFO} mobile={mobile} />;
  };

  const renderMap = () => {
    return (
      <BranchGeneralInfo map={MAP} sample={GENERAL_INFO} mobile={mobile} />
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Layout className="full">
      <Content className="home-content">
        <Row gutter={[0]}>
          {renderSectionBanner()}
          {renderMap()}
        </Row>
      </Content>
    </Layout>
  );
};

export default BranchScreen;
