export const SERVICES = [
  { id: 1, name: "Microblading 1st Session", price: 400, group: 1, off: false },
  { id: 2, name: "Microblading 2nd Session", price: 250, group: 1, off: false },
  { id: 3, name: "Microblading 3rd Session", price: 250, group: 1, off: false },
  {
    id: 4,
    name: "Microblading Combo 2 sessions",
    price: 600,
    group: 1,
    off: false,
  },
  {
    id: 5,
    name: "Microblading Yearly Freshen Up",
    price: 350,
    group: 1,
    off: false,
  },
  { id: 6, name: "Powder Brow 1st Session", price: 450, group: 2, off: false },
  { id: 7, name: "Powder Brow 2nd Session", price: 350, group: 2, off: false },
  {
    id: 8,
    name: "Powder Brow Combo 2 sessions",
    price: 750,
    group: 2,
    off: false,
  },
  {
    id: 9,
    name: "Powder Brow Yearly Freshen Up",
    price: 400,
    group: 2,
    off: false,
  },
  { id: 10, name: "Hairstrokes 1st Session", price: 550, group: 3, off: false },
  { id: 11, name: "Hairstrokes 2nd Session", price: 350, group: 3, off: false },
  {
    id: 12,
    name: "Hairstrokes Combo 2 sessions",
    price: 850,
    group: 3,
    off: false,
  },
  {
    id: 13,
    name: "Permanent Eyeliner",
    price: 450,
    group: 4,
    off: false,
  },
  {
    id: 14,
    name: "Permanent Eyeliner with Wing",
    price: 550,
    group: 4,
    off: false,
  },
  {
    id: 15,
    name: "Bottom Eyeliner",
    price: 350,
    group: 4,
    off: false,
  },
  {
    id: 16,
    name: "Permanent Eyeliner Touch Up",
    price: 300,
    group: 4,
    off: false,
  },
  {
    id: 17,
    name: "Nano Lip Contour",
    price: 500,
    group: 5,
    off: false,
  },
  {
    id: 18,
    name: "Nano Lip Blushing",
    price: 500,
    group: 5,
    off: false,
  },
  {
    id: 19,
    name: "Nano Dark Lip Neutralize",
    price: 200,
    group: 5,
    off: false,
  },
  {
    id: 20,
    name: "Combination Brow Tattoo 1st session",
    price: 500,
    group: 6,
    off: false,
  },
  {
    id: 21,
    name: "Combination Brow Tattoo 2nd session",
    price: 350,
    group: 6,
    off: false,
  },
  {
    id: 22,
    name: "Combo 2 sessions - Combination Brow Tattoo",
    price: 800,
    group: 6,
    off: false,
  },
  {
    id: 23,
    name: "Yearly Combination Brow Freshen Up",
    price: 450,
    group: 6,
    off: false,
  },
  {
    id: 24,
    name: "Eye & Brow Makeup",
    price: 65,
    group: 7,
    off: false,
  },
  {
    id: 25,
    name: "Full Makeup",
    price: 109,
    group: 7,
    off: false,
  },
  {
    id: 26,
    name: "Glam Makeup",
    price: 129,
    group: 7,
    off: false,
  },
  {
    id: 27,
    name: "Extra Lashes - Makeup",
    price: 20,
    group: 7,
    off: false,
  },
  {
    id: 28,
    name: "Basic Hairstyle (with makeup)",
    price: 49,
    group: 8,
    off: false,
  },
  {
    id: 29,
    name: "Glam Hairstyle (with makeup)",
    price: 69,
    group: 8,
    off: false,
  },
  {
    id: 30,
    name: "Basic Hairstyle",
    price: 59,
    group: 8,
    off: false,
  },
  {
    id: 31,
    name: "Glam Hairstyle",
    price: 79,
    group: 8,
    off: false,
  },
  { id: 32, name: "Sparkling Wine", price: 15, group: 9, off: false },
  { id: 33, name: "Vintage High Tea", price: 40, group: 9, off: false },
];
