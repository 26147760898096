/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Layout, BackTop, Row, Col, Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const LayoutFooter = () => {
  // -------------------------- VAR --------------------------
  const { t } = useTranslation("translation", { keyPrefix: "footer" });
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  const renderBottom = () => {
    return (
      <Row align="middle" className="bottom">
        {/* <Col xs={24} sm={8} md={8} lg={8} xl={8} align="center">
          <a
            target="_blank"
            href="https://www.professionail.com.au/_files/ugd/d5c273_310c5ced797548bc834d900719ebacb2.pdf"
          >
            Privacy Policy
          </a>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
          Copyright 2024 © Booking Beauty
        </Col>
        {/* <Col xs={24} sm={8} md={8} lg={8} xl={8} align="center">
          <a
            target="_blank"
            href="https://www.professionail.com.au/_files/ugd/d5c273_310c5ced797548bc834d900719ebacb2.pdf"
          >
            Terms & Conditions
          </a>
        </Col> */}
      </Row>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <>
      <Layout.Footer>
        <Row align="center">
          <Col xs={24} sm={24} md={24} lg={20} xl={20} align="center">
            {renderBottom()}
          </Col>
        </Row>
      </Layout.Footer>
      {/* {renderBackToTop()} */}
    </>
  );
};

export default LayoutFooter;
