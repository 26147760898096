export const GROUP = [
  { id: 1, name: "Microblading" },
  { id: 2, name: "Powder Brow" },
  { id: 3, name: "Nano Hairstrokes" },
  { id: 4, name: "Eyeliner" },
  { id: 5, name: "Nano Lip" },
  { id: 6, name: "Permanent Makeup Combination" },
  { id: 7, name: "Makeup" },
  { id: 8, name: "Hair Service" },
  { id: 9, name: "Food & Drink" },
];
