import React from "react";
import { Col, Row, Button, Typography } from "antd";

//components
import BookingBtn from "./booking_btn";
import BookingBannerXmas from "./component/booking_banner_xmas";
import { BRAND_NAME } from "../../../../config/config";
import { useSelector } from "react-redux";
import { branchDetailBySlugSelector } from "../../../../store/branch/branch.selector";
import {
  getAddressBooking,
  getLocationBooking,
  getPhoneLink,
} from "../util/general.function";

const { Title } = Typography;

const BookingBanner = ({ data, setOpen, mobile }) => {
  // -------------------------- VAR --------------------------

  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const branchDetail = useSelector(branchDetailBySlugSelector);
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  const renderSummaryStore = () => {
    return (
      <div style={{ fontSize: mobile ? "small" : "16px" }}>
        <Title className="title-banner" level={1}>
          {BRAND_NAME}
        </Title>
        <p style={{ marginRight: "1rem" }}>
          At {BRAND_NAME}, we understand that beauty needs vary from person to
          person and from one occasion to another. Whether you’re seeking a
          natural look for daily life or a glamorous transformation for a
          special event, we have the skills and creativity to make it happen.
        </p>
        {/* {renderOpenHours()} */}
        {renderLocation()}
        {renderAddress()}
        <p style={{ marginTop: "1rem" }}>
          Should you have any queries, please feel free to contact us.
        </p>
        {renderPhone()}
        {renderEmail()}
        {renderQoute()}
      </div>
    );
  };

  const renderLocation = () => {
    // const location = getLocationBooking(branchDetail);
    const location = branchDetail?.location;
    return (
      <div
        style={{
          display: "flex",
          width: "auto",
          marginTop: "1rem",
        }}
      >
        <b>Located in:</b>
        <div style={{ marginLeft: "1rem" }}>{location}</div>
      </div>
    );
  };

  const renderAddress = () => {
    const address = getAddressBooking(branchDetail);
    return (
      <div
        style={{
          display: "flex",
          width: "auto",
        }}
      >
        <b>Address:</b>
        <div style={{ marginLeft: "1rem" }}>{address}</div>
      </div>
    );
  };

  const renderPhone = () => {
    const phone = branchDetail?.phone;
    if (phone) {
      return (
        <div
          style={{
            display: "flex",
            width: "auto",
            marginTop: "1rem",
          }}
        >
          <b>Phone:</b>
          <a
            style={{ marginLeft: "1rem", color: "#AF8365" }}
            href={getPhoneLink(branchDetail?.phone)}
          >
            {phone}
          </a>
        </div>
      );
    }
  };

  const renderEmail = () => {
    const email = branchDetail?.email;
    return (
      <div
        style={{
          display: "flex",
          width: "auto",
        }}
      >
        <b>Email:</b>
        <div
          style={{
            marginLeft: "1rem",
          }}
        >
          {email}
        </div>
      </div>
    );
  };

  const renderQoute = () => {
    return (
      <div style={{ margin: "20px 0px 0px" }}>
        For same day appointments, please walk in or call our salon directly via
        the phone number above
      </div>
    );
  };

  const renderXmasOpeningHours = () => {
    return <BookingBannerXmas />;
  };

  // -------------------------- MAIN --------------------------
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Row className="booking-banner" gutter={[0, 20]}>
        <Col
          className="booking-info"
          xs={24}
          sm={24}
          md={24}
          lg={13}
          xl={13}
          xxl={13}
        >
          {renderSummaryStore()}
          <BookingBtn mobile={mobile} setOpen={setOpen} />
          {/* {renderXmasOpeningHours()} */}
        </Col>
      </Row>
    </Col>
  );
};

export default BookingBanner;
