import {
  collection,
  getFirestore,
  doc,
  where,
  getDocs,
  query,
  updateDoc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
//   import slugify from "slugify";
// import moment from "moment";
import { app } from "../../config/config";
//   import { getAuth } from "firebase/auth";
import { TYPES } from "./branch.type";
import { REF } from "../ref";
//   import { getFunctions, httpsCallable } from "firebase/functions";
//   import { REGION } from "../ref";
//   import { message } from "antd";

const firestore = getFirestore(app);
//   const auth = getAuth(app);
//   const functions = getFunctions(app, REGION);

//get all branches
export const getAllBranches = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.BRANCH);
  getDocs(cRef).then((snapshot) => {
    if (snapshot?.docs?.length > 0) {
      const data = snapshot?.docs?.map((doc) => doc.data());
      getAllBranchesSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
};

export const getBranchBySlug =
  ({ slug }, callback) =>
  (dispatch) => {
    getBranchDetailBySlugSuccess(dispatch, null);
    if (slug) {
      const cRef = collection(firestore, REF.BRANCH);
      const c0 = where("slug", "==", slug);
      const qRef = query(cRef, c0);
      getDocs(qRef).then((snapshot) => {
        if (snapshot?.docs?.length > 0) {
          const detail = snapshot?.docs[0]?.data();
          getBranchDetailBySlugSuccess(dispatch, detail);
          if (callback) {
            callback();
          }
        }
      });
    }
  };

export const addBranch =
  ({ data }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.BRANCH);
    const id = doc(cRef).id;
    const create = {
      ...data,
      id,
      createAt: serverTimestamp(),
      updateAt: serverTimestamp(),
    };

    const dRef = doc(cRef, id);
    setDoc(dRef, create, { merge: true }).then(() => {
      if (callback) {
        callback({
          status: 200,
          data: create,
        });
      }
    });
  };

export const updateBranch =
  ({ id, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.BRANCH);
    const dRef = doc(cRef, id);
    const updateField = {
      ...update,
      updateAt: serverTimestamp(),
    };
    updateDoc(dRef, updateField)
      .then(() => {
        if (callback) {
          callback({
            status: 200,
            data: update,
          });
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

const getAllBranchesSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.GET_ALL_BRANCHES,
    payload: data,
  });
};

const getBranchDetailBySlugSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.GET_BRANCH_DETAIL_BY_SLUG,
    payload: data,
  });
};
