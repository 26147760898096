import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Grid, Typography, Button, BackTop } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

import { HiHome } from "react-icons/hi";
// import {FiHome} from "react-ioncs/fi";
import { BRAND_NAME } from "../../../config/config";
import logo from "../../../config/img/logo-512.png";

const { useBreakpoint } = Grid;
const { Title } = Typography;

const HOME_DOMAIN = "https://tbbsalon.co.nz/";

const LayoutHeader = () => {
  // -------------------------- VAR --------------------------

  // -------------------------- STATE --------------------------
  const [mobile, setMobile] = useState(false);
  const screens = useBreakpoint();
  // -------------------------- REDUX --------------------------

  // -------------------------- FUNCTION ------------------------
  const backtoHome = () => {
    window.location.href = HOME_DOMAIN;
  };

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (screens?.xs) {
      setMobile(true);
    }
    if (screens?.sm) {
      setMobile(true);
    }
    if (screens?.md) {
      setMobile(true);
    }
    if (screens?.lg) {
      setMobile(false);
    }
    if (screens?.xl) {
      setMobile(false);
    }
    if (screens?.xxl) {
      setMobile(false);
    }
  }, [screens]);

  // -------------------------- RENDER --------------------------

  const renderLogo = () => {
    return (
      // <a href="/" target="_self" rel="noopener noreferrer">
      //   <div className="logo" />
      // </a>
      <img
        src={logo}
        alt="Logo"
        className="logo"
        style={{ height: mobile ? "100px" : "172px", width: "auto" }}
      />
    );
  };

  const renderDesktop = () => {
    return (
      <Row
        style={{
          position: "relative",
        }}
        justify="space-between"
        align="middle"
      >
        <Col style={{ position: "absolute" }} flex="150px">
          {renderLogo()}
        </Col>
        <Col className="menu-title-navbar" flex="auto" align="center">
          <Title level={2}>{BRAND_NAME}</Title>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyItems: "center",
            position: "absolute",
            right: "0px",
          }}
        >
          <Button
            type="text"
            icon={
              <span className="anticon">
                <HiHome size={25} color="#AF8365" />
              </span>
            }
            onClick={backtoHome}
          />
          {/* <button onclick={() => console.log("Clicked!")}>Click me</button> */}
        </Col>
      </Row>
    );
  };

  const renderMobile = () => {
    return (
      <Row style={{ marginTop: "-30px" }} align="middle">
        <Col
          style={{
            paddingLeft: "7px",
          }}
        >
          {renderLogo()}
        </Col>
        <Col
          style={{
            display: "flex",
            justifyItems: "center",
            position: "absolute",
            right: "1rem",
            paddingTop: "7px",
          }}
        >
          <Button
            type="text"
            icon={
              <span className="anticon">
                <HiHome size={28} color="#AF8365" />
              </span>
            }
            onClick={backtoHome}
          />
        </Col>
      </Row>
    );
  };

  const renderBackToTop = () => {
    return (
      <BackTop>
        <div
          style={{
            height: 40,
            width: 40,
            lineHeight: "40px",
            borderRadius: 4,
            backgroundColor: "#8c7c6f",
            textAlign: "center",
            color: "#fff",
            fontSize: 17,
          }}
        >
          <ArrowUpOutlined />
        </div>
      </BackTop>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <>
      <Layout.Header className="header-menu">
        {mobile ? renderMobile() : renderDesktop()}
      </Layout.Header>
      {renderBackToTop()}
    </>
  );
};

export default LayoutHeader;
