import React from "react";
import moment from "moment";
import { GiAlarmClock } from "react-icons/gi";
import { GoCalendar } from "react-icons/go";
//outsource
import { Row, Col } from "antd";

//components

const SelectedDateTime = ({ selectedDatetime }) => {
  // -------------------------- VAR --------------------------
  const date =
    moment(selectedDatetime).format("Do MMM YYYY") ||
    moment().format("Do MMM YYYY");
  const time =
    moment(selectedDatetime).format("hh:mm A") || moment().format("hh:mm A");
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <div
      style={{ marginTop: "1rem", padding: "10px" }}
      className="booking-selected"
    >
      <Row gutter={[10, 10]}>
        <Col style={{ fontWeight: "bold" }} align="left" span={24}>
          Booking on:
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          align="left"
          span={24}
        >
          <GoCalendar style={{ margin: "0rem 1rem 0rem 0.3rem" }} size={23} />
          <span>{date}</span>
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          align="left"
          span={24}
        >
          <GiAlarmClock style={{ margin: "0rem 1rem 0rem 0.3rem" }} size={23} />
          <span>{time}</span>
        </Col>
        <b style={{ textAlign: "center", width: "100%", fontSize: "small" }}>
          **Only hold booking for 10mins**
        </b>
      </Row>
    </div>
  );
};

export default SelectedDateTime;
