import React, { useState, useEffect } from "react";
//outsource
import { Row, Col, Space, Tooltip, Button, Grid } from "antd";
//components
import BlackWhite from "../../../../asset/img/black-white-icon-3.png";
import { useSelector } from "react-redux";
import { branchDetailBySlugSelector } from "../../../../store/branch/branch.selector";
import {
  SOCIAL_NETWORK,
  SOCIAL_NETWORK_ICON_MAP,
} from "../../../../store/data/data";
import { getAddressBooking, getPhoneLink } from "../util/general.function";

const { useBreakpoint } = Grid;

const BookingGeneralInfo = (props) => {
  // -------------------------- VAR --------------------------
  const { map, sample } = props;
  const screens = useBreakpoint();
  // -------------------------- STATE --------------------------
  const [mobile, setMobile] = useState(3);

  // -------------------------- REDUX --------------------------
  const branchDetail = useSelector(branchDetailBySlugSelector);

  const socialNetworks = branchDetail?.socialNetworks || SOCIAL_NETWORK;
  const branchMap = branchDetail?.map || map;

  const socialNetworksWIcon = socialNetworks.map((network) => ({
    ...network,
    icon: SOCIAL_NETWORK_ICON_MAP[network?.title],
  }));
  // -------------------------- FUNCTION ------------------------
  const handleNavigate = (src) => {
    window.location.href = src;
  };

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (screens?.xs) {
      setMobile(1);
    }
    if (screens?.sm) {
      setMobile(1);
    }
    if (screens?.md) {
      setMobile(2);
    }
    if (screens?.lg) {
      setMobile(2);
    }
    if (screens?.xl) {
      setMobile(3);
    }
    if (screens?.xxl) {
      setMobile(3);
    }
  }, [screens]);

  // -------------------------- RENDER --------------------------
  const mainContentBody = () => {
    if (mobile < 2) {
      return renderMobileBody();
    }

    return renderBody();
  };

  const renderMobileBody = () => {
    return (
      <Row style={{ padding: "0px 20px" }} gutter={[30, 20]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          {renderContentLeft()}
        </Col>
        <Col
          style={{ marginTop: mobile > 1 ? "10rem" : "1rem" }}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
        >
          {renderContentRight()}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          {renderContentMap()}
        </Col>
      </Row>
    );
  };

  const renderBody = () => {
    return (
      <Row style={{ padding: "0px 20px" }} gutter={[30, 20]}>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          {renderContentLeft()}
        </Col>
        <Col
          className="branch-footermobile-mt"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
        >
          {renderContentMap()}
        </Col>
        <Col
          className="branch-footermobile-mt"
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
        >
          {renderContentRight()}
        </Col>
      </Row>
    );
  };

  const renderContentLeft = () => {
    return (
      <div
        style={{
          marginTop: "3rem",
          fontSize: mobile === 1 ? "small" : "medium",
          textAlign: mobile === 1 ? "center" : "left",
        }}
      >
        <img
          style={{
            marginLeft: mobile > 2 ? "-5px" : "",
            marginBottom: mobile > 2 ? "10px" : "",
            width: mobile === 1 ? "50%" : "300%",
            // filter: "invert(100%)",
          }}
          src={BlackWhite}
          alt="black_and_white"
        />
        <p className="address-text">
          Specialising in lashes, brows,nails, permanent makeup, and makeup
          services, we offer a haven of tranquility where your beauty desires
          come to life.
        </p>
        <Space>
          {socialNetworksWIcon?.map((item) => {
            const src = item?.title.includes("email")
              ? `mailto:${branchDetail?.email}`
              : item?.src;
            return (
              <Tooltip key={item?.title} placement="top" title={item?.title}>
                <Button
                  onClick={() => handleNavigate(src)}
                  icon={<span className="anticon">{item?.icon}</span>}
                />
              </Tooltip>
            );
          })}
        </Space>
      </div>
    );
  };

  const renderContentMap = () => {
    return (
      <iframe
        src={branchMap}
        width="100%"
        height={mobile === 1 ? "200" : "500"}
        style={{ border: 0 }}
        loading="lazy"
        // referrerpolicy="no-referrer-when-downgrade"
      />
    );
  };

  const renderContentRight = () => {
    const openHours = branchDetail?.openHours || sample?.openHours;
    const address = getAddressBooking(branchDetail) || sample?.address;
    const phone = branchDetail?.phone || sample?.phone;
    const parking = branchDetail?.parking || sample?.parking;
    return (
      <>
        <div>
          <b style={{ color: "white", fontSize: "18px" }}>Bussiness Hours: </b>
          {openHours?.map((item) => (
            <div className="opening-hours" key={item?.days}>
              <div>{item?.days}:</div>
              <div>
                {item?.from && item?.to
                  ? `${item?.from} - ${item?.to}`
                  : "Closed"}
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginTop: "3rem" }}>
          <p className="address-text" style={{ fontSize: "14px" }}>
            {parking}
          </p>
        </div>
        <div style={{ marginTop: "3rem" }}>
          <b style={{ color: "white", fontSize: "17px" }}>Address: </b>
          <span className="address-text">{address}</span>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <b style={{ color: "white", fontSize: "17px" }}>Phone: </b>
          <a
            className="address-text"
            style={{ marginLeft: "1rem", color: "black" }}
            href={branchDetail ? getPhoneLink(branchDetail?.phone) : null}
          >
            {phone}
          </a>
        </div>
      </>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Row className="home-general-info" align="center">
      <Col
        style={{ fontSize: mobile ? "small" : "medium" }}
        xs={24}
        sm={24}
        md={24}
        lg={20}
        xl={20}
        xxl={20}
      >
        {mainContentBody()}
      </Col>
    </Row>
  );
};

export default BookingGeneralInfo;
