//components
import { useSelector } from "react-redux";
import placeholder from "../../../../../asset/img/placeholder.png";
import { branchDetailBySlugSelector } from "../../../../../store/branch/branch.selector";
import branchGettimelyImg from "../../../../../asset/img/other-nail-booking.png";
//actions
//selector
//function
//str

const BranchGettimely = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const branchDetail = useSelector(branchDetailBySlugSelector);
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <div className="branch-booking-item">
      <a
        href={branchDetail?.branchGettimelyLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={branchGettimelyImg || placeholder}
          alt="gettimely"
          className={
            branchGettimelyImg
              ? "booking-location-item-img"
              : "booking-location-item-img-placeholder"
          }
        />
      </a>
    </div>
  );
};
export default BranchGettimely;
