import React, { useState, useEffect } from "react";
import { Layout, Grid, Row } from "antd";
// import { useDispatch, useSelector } from "react-redux";
// component

import BookingBanner from "./component/booking_banner";
import BookingGeneralInfo from "./component/booking_general_info";
// import HomeXmasBtn from "./component/home_xmas_btn";
import DialogBookingAdd from "./dialog/dialog_booking_add";
import DialogBookingSuccess from "./dialog/dialog_booking_success";
import DialogBookingCancel from "./dialog/dialog_booking_cancel";

//redux
// import {
//   getServiceGroup,
//   getServiceList,
// } from "../../store/service/service.action";
// import {
//   groupServiceSelector,
//   listServiceSelector,
// } from "../../store/service/service.selector";
// import {
//   deleteService,
//   createServices,
// } from "../../store/service/service.action";

// strings
import { SERVICES } from "../../../store/data/services";
import { GENERAL_INFO, MAP } from "../../../store/data/data";
import "./style.css";
import { GROUP } from "../../../store/data/group";
import { getBranchBySlug } from "../../../store/branch/branch.action";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { transformedFormat } from "./util/general.function";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const BookingScreen = () => {
  // -------------------------- VAR --------------------------
  const { branchSlug } = useParams();
  const screens = useBreakpoint();
  // -------------------------- STATE --------------------------
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);

  const [mobile, setMobile] = useState(false);

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // const group = useSelector(groupServiceSelector);
  // const list = useSelector(listServiceSelector);
  const group = GROUP;
  const list = SERVICES;
  // const converted = transformedFormat(SERVICES);
  // -------------------------- FUNCTION -----------------------
  // const handleDelete = () => {
  //   dispatch(deleteService());
  // };

  // const handleCreate = () => {
  //   dispatch(createServices({ services: converted }));
  // };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(getBranchBySlug({ slug: branchSlug }));
  }, [branchSlug, dispatch]);

  useEffect(() => {
    if (screens?.xs) {
      setMobile(true);
    }
    if (screens?.sm) {
      setMobile(true);
    }
    if (screens?.md) {
      setMobile(true);
    }
    if (screens?.lg) {
      setMobile(false);
    }
    if (screens?.xl) {
      setMobile(false);
    }
    if (screens?.xxl) {
      setMobile(false);
    }
  }, [screens]);

  // -------------------------- RENDER --------------------------

  // Render Section Banner
  const renderSectionBanner = () => {
    return (
      <BookingBanner data={GENERAL_INFO} setOpen={setOpen} mobile={mobile} />
    );
  };

  // const renderHomeXmas = () => {
  //   return <HomeXmasBtn mobile={mobile} />;
  // };

  const renderMap = () => {
    return (
      <BookingGeneralInfo map={MAP} sample={GENERAL_INFO} mobile={mobile} />
    );
  };

  const renderModal = () => {
    return (
      <DialogBookingAdd
        open={open}
        setOpenSuccess={setOpenSuccess}
        setOpen={setOpen}
        setOpenCancel={setOpenCancel}
        group={group}
        list={list}
      />
    );
  };

  const renderModalSuccess = () => {
    return (
      <DialogBookingSuccess
        open={openSuccess}
        setOpenSuccess={setOpenSuccess}
      />
    );
  };

  const renderCancelPolicy = () => {
    return <DialogBookingCancel open={openCancel} setOpen={setOpenCancel} />;
  };

  // const renderGroupBtn = () => {
  //   return (
  //     <div style={{ position: "fixed", bottom: 20, right: 20 }}>
  //       {/* <Button onClick={handleDelete}>Delete Services</Button>
  //       <Button onClick={handleCreate} type="primary">
  //         Create Services
  //       </Button> */}
  //     </div>
  //   );
  // };

  // -------------------------- MAIN --------------------------
  return (
    <Layout className="full">
      <Content className="home-content">
        <Row gutter={[0]}>
          {renderSectionBanner()}
          {/* {renderHomeXmas()} */}
          {renderMap()}
          {renderModal()}
          {renderModalSuccess()}
          {renderCancelPolicy()}
          {/* {renderGroupBtn()} */}
        </Row>
      </Content>
    </Layout>
  );
};

export default BookingScreen;
