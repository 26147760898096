import React, { useEffect } from "react";
//outsource
import { Row, Col, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { getDatafromCollection } from "../../store/dev/dev.action";
import { createService } from "../../store/dev/dev.action";
import { getAllBranches, updateBranch } from "../../store/branch/branch.action";
import { allBranchesSelector } from "../../store/branch/branch.selector";
import { convertImageToBase64 } from "../01_Home/util/general.function";
import { GENERAL_INFO, SOCIAL_NETWORK } from "../../store/data/data";
import manukau from "../../asset/img/manukau-2.png";
import manawa from "../../asset/img/manawa-1.png";
//components

const DevScreen = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const allBranches = useSelector(allBranchesSelector);
  // -------------------------- FUNCTION --------------------------
  const showData = async () => {
    // dispatch(createService());
    if (allBranches?.length > 0) {
      const branch = allBranches?.find((branch) => branch?.slug === "manukau");
      const bookingImg = await convertImageToBase64(manukau);
      const update = {
        ...branch,
        // openHours: GENERAL_INFO.openHours,
        // socialNetworks: SOCIAL_NETWORK,
        banner: bookingImg,
      };
      console.log({ allBranches, branch, update });
      dispatch(updateBranch({ id: branch?.id, update }));
    }
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(getAllBranches());
  }, [dispatch]);
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Col style={{ margin: "20px" }}>
      <Button onClick={showData}>update Data</Button>
    </Col>
  );
};

export default DevScreen;
