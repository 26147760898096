import React from "react";
//outsource
import { Row, Col, Button, Tooltip } from "antd";

//components

const SelectedServices = ({ selectedService, selected, setSelected }) => {
  // -------------------------- VAR --------------------------
  const total =
    selectedService
      ?.map((item) => {
        const sumItem = item?.amount * item?.price;
        return sumItem;
      })
      .reduce((a, b) => a + b, 0) || 0;

  const sumAmount =
    selectedService
      ?.map((item) => {
        return item?.amount;
      })
      .reduce((a, b) => a + b, 0) || 0;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION -----------------------
  const handleIncrease = (number, id) => {
    const plus = number + 1;
    const newSelected = selected?.map((obj) => {
      return obj?.service === id ? { ...obj, amount: plus } : obj;
    });

    setSelected(newSelected);
  };

  const handleDecrease = (number, id) => {
    const minor = number <= 1 ? number : number - 1;
    const newSelected = selected?.map((obj) => {
      return obj?.service === id ? { ...obj, amount: minor } : obj;
    });

    setSelected(newSelected);
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  const renderTotal = () => {
    return (
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          padding: "10px",
          fontSize: "medium",
          fontWeight: "bold",
        }}
      >
        <div>TOTAL:</div>
        <div>
          {sumAmount} service(s) -{" "}
          <span style={{ fontWeight: "bold" }}>${total}</span>
        </div>
      </div>
    );
  };

  const renderAmount = (item) => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#40352f",
            border: "none",
            borderRadius: "5px",
            color: "white",
            padding: "3px",
            width: "30px",
          }}
        >
          x{item?.amount}
        </div>
      </>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <div className="booking-selected">
      {selectedService?.map((item) => {
        const sum = item?.price * item?.amount;
        const number = item?.amount || 0;
        const service = item?.service;
        const diabled = item?.amount === 1;
        return (
          <Row
            key={item?.service}
            style={{
              margin: "1rem 0rem",
              fontWeight: "bold",
            }}
            gutter={[10]}
            align="top"
          >
            <Col align="center" span={4}>
              {renderAmount(item)}
            </Col>
            <Col align="left" flex="auto" span={14}>
              {`${item?.name} - $${item?.price} ea.`}
              <div>${sum}</div>
            </Col>
            <Col
              justify="bottom"
              style={{ display: "flex", gap: "1px" }}
              span={6}
            >
              <Tooltip title="Decrease">
                <Button
                  type="default"
                  style={{
                    width: "28px",
                    height: "28px",
                    border: "1px solid rgba(0,0,0,0.85)",
                  }}
                  disabled={diabled}
                  icon="-"
                  onClick={() => handleDecrease(number, service)}
                />
              </Tooltip>
              <Tooltip title="Increase">
                <Button
                  type="default"
                  style={{
                    border: "1px solid rgba(0,0,0,0.85)",
                    width: "28px",
                    height: "28px",
                  }}
                  icon="+"
                  onClick={() => handleIncrease(number, service)}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      })}

      {renderTotal()}
    </div>
  );
};

export default SelectedServices;
