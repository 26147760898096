import React from "react";
import { Col, Row, Button, Typography, List, Collapse } from "antd";

import { useSelector } from "react-redux";
//components

import { branchDetailBySlugSelector } from "../../../../store/branch/branch.selector";
import BranchBooking from "./component/branch_booking";
import BranchGettimely from "./component/branch_gettimely";

const { Title } = Typography;

const BranchBanner = ({ mobile }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  const renderSummaryStore = () => {
    return (
      <div style={{ fontSize: mobile ? "small" : "16px" }}>
        <Title className="branch-title" level={6}>
          BOOKING
        </Title>
        <div className="location-list">
          <div className="align-end">
            <BranchBooking />
          </div>
          <div className="align-start">
            <BranchGettimely />
          </div>
        </div>
      </div>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Row className="branch-banner" gutter={[0, 20]}>
        <Col
          className="booking-info"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          {renderSummaryStore()}
        </Col>
        {/* <Button onClick={handleClick}>Test</Button> */}
      </Row>
    </Col>
  );
};

export default BranchBanner;
