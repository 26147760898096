export const REGION = "asia-southeast1";
// local
export const LOCAL_USER = "Local User";
// ref
export const REF = {
  GROUP: "group",
  SERVICE: "service",
  BOOKING: "booking",
  BRANCH: "branch",
};
