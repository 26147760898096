//components
import { useSelector } from "react-redux";
import placeholder from "../../../../../asset/img/placeholder.png";
import { branchDetailBySlugSelector } from "../../../../../store/branch/branch.selector";
import branchBookingImg from "../../../../../asset/img/makeup-booking.png";
//actions
//selector
//function
//str

const BranchBooking = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <div className="branch-booking-item">
      <a
        href={`${window.location.href}/booking`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={branchBookingImg || placeholder}
          alt="booking"
          className={
            branchBookingImg
              ? "booking-location-item-img"
              : "booking-location-item-img-placeholder"
          }
        />
      </a>
    </div>
  );
};
export default BranchBooking;
