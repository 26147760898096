import React from "react";
//outsource
import { Col, Input, Form } from "antd";

//components

const BookingFirstName = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="firstName"
      label="Enter your first Name"
      rules={[
        {
          required: true,
          message: "First Name is empty",
        },
      ]}
    >
      <Input placeholder="First Name" />
    </Form.Item>
  );
};

export default BookingFirstName;
