import { Collapse, Checkbox, Row, Col, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//redux
import { selectService } from "../../../../../store/service/service.action";

const { Panel } = Collapse;
const { Text } = Typography;

const SelectService = ({
  group,
  list,
  selected,
  setSelected,
  selectedService,
}) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  const [data, setData] = useState(null);

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    const service = e.target.value;
    const checked = e.target.checked;
    const name = list?.find((item) => item?.id === service).name;
    const price = list?.find((item) => item?.id === service).price;
    const amount = 1;

    const data = {
      service,
      checked,
      name,
      price,
      amount,
    };

    //Add new when selected dont have
    if (checked) {
      setSelected((selected) => {
        const newData = [...selected, data];
        selectService(dispatch, newData);
        return newData;
      });
    } else {
      const remove = selected?.filter((item) => item?.name !== name);
      setSelected(remove);
      selectService(dispatch, remove);
    }
  };

  // const handleNumberChange = (number, id) => {
  //   const newUpdate = selected.map((obj) =>
  //     obj?.service === id ? { ...obj, amount: number ? number : 0 } : obj
  //   );

  //   setSelected(newUpdate);
  //   // selectService(dispatch, newUpdate);
  // };

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (group) {
      // const sortFormat = lodash.sortBy(group, ["name"]);
      setData(group);
    }
  }, [group]);

  useEffect(() => {
    selectService(dispatch, selected);
  }, [selected, dispatch]);

  // -------------------------- RENDER --------------------------

  const renderItem = (item) => {
    const groupId = item?.id;
    const listItem = list
      ?.filter((row) => row?.group === groupId)
      ?.sort((a, b) => a.order - b.order);
    const checked = selectedService?.map((row) => row.service);
    const newFormat = listItem?.map((item) => {
      const key = item?.id;
      const value = item?.id;
      const name = item?.name;
      const price = item?.price;
      const off = item?.off;
      const from = item?.from || false;

      return {
        key,
        value,
        name,
        price,
        off,
        from,
      };
    });
    return (
      <Checkbox.Group
        style={{ width: "100%", margin: "-1rem 0rem 0rem 1.5rem" }}
        value={checked}
      >
        {newFormat.map((item) => {
          const off = item?.off || null;
          if (off) {
            return null;
          }

          return (
            <Col style={{ margin: "0.8rem 0rem" }} key={item?.key} span={24}>
              <Row className="checkbox-item">
                <Col span={2}>
                  <Checkbox
                    onChange={handleChange}
                    value={item?.value}
                    // checked={checked}
                  />
                </Col>
                <Col span={16}>
                  <Text style={{ width: "100%", flexWrap: "wrap" }}>
                    {item?.name} {item?.from ? "*" : null}
                  </Text>
                </Col>
                <Col style={{ display: "flex", alignItems: "center" }} span={4}>
                  <span style={{ marginRight: "0.7rem" }}>${item?.price}</span>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Checkbox.Group>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Collapse defaultActiveKey={["1"]} ghost>
      {data?.map((item) => (
        <Panel key={item?.id} header={item?.name}>
          {renderItem(item)}
        </Panel>
      ))}
    </Collapse>
  );
};

export default SelectService;
