import { TYPES } from "./language.type";

const initState = {
  language: "en",
};

const languageReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.CURRENT_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    default:
      return state;
  }
};

export default languageReducer;
