import { TYPES } from "./branch.type";

const initState = {
  tab: "manual",
  allBranches: null,
  branchDetailBySlug: null,
};
const branchReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.GET_ALL_BRANCHES:
      return {
        ...state,
        allBranches: payload,
      };
    case TYPES.GET_BRANCH_DETAIL_BY_SLUG:
      return {
        ...state,
        branchDetailBySlug: payload,
      };

    //default
    default:
      return state;
  }
};

export default branchReducer;
