import React from "react";
import { MdCancel } from "react-icons/md";

//outsource
import { Col, Modal, Button } from "antd";

const DialogCustomerPolicy = ({ open, setOpen }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- FUNCTION --------------------------

  const handleDone = () => {
    setOpen(false);
    // selectStepService(dispatch, 0);
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  const renderButtonFooter = () => {
    return (
      <Button className="btn-booking-done" onClick={handleDone} type="primary">
        Back
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Modal
      open={open}
      title={null}
      closable={false}
      footer={renderButtonFooter()}
    >
      <div style={{ textAlign: "center", padding: "0px 60px" }}>
        <h1>Customer Policy</h1>
      </div>
      <div style={{ textAlign: "left" }}>
        <p>
          &ndash; We provide guarantee for French polish or Gel nail polish for
          3 days on natural nails.
        </p>
        <p>
          &ndash; We are responsible in the event of losing nail or lifting
          occurs within 3 days on Acrylic nails.
        </p>
        <p>
          &ndash; We are not responsible for breaking or damaging your nails by
          accident.
        </p>
        <p>
          &ndash; There might be extra charges for refills that are later than 2
          weeks.
        </p>
        <p>
          &ndash; Please make decision for the nail polish of your choice, as
          there will be additional charges if you change your mind.
        </p>
        <p>&ndash; There is no refund will be given in any cases.</p>
        <p>
          &ndash; We promise to provide the best quality and highest
          satisfaction to all customers.
        </p>
        <p style={{ color: "red" }}>
          *These guarantees apply only to clients from this branch upon
          receipts.
        </p>
      </div>
    </Modal>
  );
};

export default DialogCustomerPolicy;
