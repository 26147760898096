import React, { useState, useEffect } from "react";
import { Layout, Grid, Row } from "antd";
// component

import HomeBanner from "./component/home_banner";

//redux

// strings
import { GENERAL_INFO } from "../../store/data/data";
import "./style.css";
import { useDispatch } from "react-redux";
import { getAllBranches } from "../../store/branch/branch.action";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const HomeScreen = () => {
  // -------------------------- VAR --------------------------

  const screens = useBreakpoint();
  // -------------------------- STATE --------------------------

  const [mobile, setMobile] = useState(false);

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION -----------------------

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(getAllBranches());
  }, [dispatch]);

  useEffect(() => {
    if (screens?.xs) {
      setMobile(true);
    }
    if (screens?.sm) {
      setMobile(true);
    }
    if (screens?.md) {
      setMobile(true);
    }
    if (screens?.lg) {
      setMobile(false);
    }
    if (screens?.xl) {
      setMobile(false);
    }
    if (screens?.xxl) {
      setMobile(false);
    }
  }, [screens]);

  // -------------------------- RENDER --------------------------

  // Render Section Banner
  const renderSectionBanner = () => {
    return <HomeBanner data={GENERAL_INFO} mobile={mobile} />;
  };

  // -------------------------- MAIN --------------------------
  return (
    <Layout className="full">
      <Content className="home-content">
        <Row gutter={[0]}>{renderSectionBanner()}</Row>
      </Content>
    </Layout>
  );
};

export default HomeScreen;
