import React from "react";
import { BsChevronDown } from "react-icons/bs";

export const URL = {
  // url
  home: "/",
  dev: "/dev",
  branch: "/salon/:branchSlug",
  branchBooking: "/salon/:branchSlug/booking",
};

export const newItems = (t, language) => {
  const items = {
    home: {
      title: t("home"),
      url: language,
      key: "1",
      hightlight: ["1"],
      arrow: false,
    },
  };
  return items;
};

export const newBreadCrumb = (items) => {
  const breadCrumb = {
    home: null,
  };
  return breadCrumb;
};

export const setMenuItem = (props) => {
  const { title, url, key, arrow, icon, children } = props;
  const href = url;
  let label = href ? (
    <>
      <a href={href} target="_self" rel="noopener noreferrer">
        {title}
      </a>
      {arrow ? (
        <BsChevronDown
          style={{ fontSize: 10, marginLeft: 5 }}
          className="arrow-down"
        />
      ) : null}
    </>
  ) : (
    title
  );
  return { key, children, label, icon };
};

export const newMenus = ({ items, categories, language }) => {
  const menu = [setMenuItem({ ...items.home })];
  return menu;
};
