import React from "react";
//outsource
import { Button } from "antd";
//components

const BookingBtn = ({ mobile, setOpen }) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX -------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Button
      className="booking-btn"
      type="primary"
      style={{ fontSize: mobile ? "small" : "medium" }}
      onClick={() => setOpen(true)}
    >
      BOOK NOW
    </Button>
  );
};
export default BookingBtn;
