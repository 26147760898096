import React from "react";
//outsource
import { Col, Form, Row } from "antd";

//components
import BookingFirstName from "./component/booking_first_name";
import BookingLastName from "./component/booking_last_name";
import BookingPhone from "./component/booking_phone";
import BookingEmail from "./component/booking_email";
import BookingNote from "./component/booking_note";

const SelectInformation = ({ form, onFinish, onFinishFailed }) => {
  // -------------------------- VAR --------------------------

  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION ------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  const renderBody = () => {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <BookingFirstName />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <BookingLastName />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <BookingPhone />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <BookingEmail />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <BookingNote />
        </Col>
      </Row>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Form
      id="modal"
      type="basic"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {renderBody()}
    </Form>
  );
};

export default SelectInformation;
