import React from "react";
//outsource
import { Col, Input, Form } from "antd";

//components

const BookingLastName = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="lastName"
      label="Enter your Last Name"
      rules={[
        {
          required: true,
          message: "Last Name is empty",
        },
      ]}
    >
      <Input placeholder="Last Name" />
    </Form.Item>
  );
};

export default BookingLastName;
