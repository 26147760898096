import React from "react";

//outsource
import {  Modal, Button } from "antd";

const DialogBookingCancel = ({ open, setOpen }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- FUNCTION --------------------------

  const handleDone = () => {
    setOpen(false);
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  const renderButtonFooter = () => {
    return (
      <Button className="btn-booking-done" onClick={handleDone} type="primary">
        Back
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Modal
      open={open}
      title={null}
      closable={false}
      footer={renderButtonFooter()}
    >
      <div style={{ textAlign: "center", padding: "0px 60px" }}>
        <h1>Booking Policy</h1>
      </div>
      <div style={{ textAlign: "left" }}>
        <p>
          &ndash; Our customer support team will contact you to confirm your
          appointment.
        </p>
        <p>
          &ndash; Please ensure that you arrive on time for your appointment, as
          we can only hold your booking for 10 minutes. If you are more than 10
          minutes late for your scheduled appointment, please give us a call as
          soon as possible. While we will do our best to accommodate you, we
          cannot guarantee a different time slot or the completion of your
          service if you are late.
        </p>
        <p>
          &ndash; Please note that we reserve the right to delay your
          appointment, and a wait time may occur. We will make every effort to
          inform you of any delays and provide you with an updated appointment
          time. We appreciate your understanding in these situations and will do
          our best to start your appointment as soon as possible.
        </p>
      </div>
    </Modal>
  );
};

export default DialogBookingCancel;
