import {
  collection,
  getFirestore,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getStorage,
  getDownloadURL,
  uploadString,
  ref,
} from "firebase/storage";
import moment from "moment";
import { app } from "../../config/config";
import { REF, REGION } from "../ref";
import { TYPES } from "./service.type";
import { BOT_URL, CHAT_ID } from "../string";
import axios from "axios";

const functions = getFunctions(app, REGION);
const firestore = getFirestore(app);
const storage = getStorage();

// --------------- getServiceGroup -----------------
export const getServiceGroup = (callback) => (dispatch) => {
  getGroupServiceSuccess(dispatch, null);
  const cRef = collection(firestore, REF.GROUP);
  return getDocs(cRef).then((snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    getGroupServiceSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  });
};

export const getServiceList = (callback) => (dispatch) => {
  getListServiceSuccess(dispatch, null);
  const cRef = collection(firestore, REF.SERVICE);
  return getDocs(cRef).then((snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    getListServiceSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  });
};

// --------------- dispatch -----------------

const getGroupServiceSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.GET_SERVICE_GROUP_SUCCESS,
    payload: data,
  });
};

const getListServiceSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.GET_SERVICE_LIST_SUCCESS,
    payload: data,
  });
};

// ---------------- dispatch ------------------

export const selectService = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_SERVICE_SUCCESS,
    payload: data,
  });
};

export const selectFullDateService = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_SERVICE_DATETIME_SUCCESS,
    payload: data,
  });
};

export const selectStepService = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_SERVICE_STEP_SUCCESS,
    payload: data,
  });
};

// -------------- add the booking nail service ---------

export const addBookingInfo =
  ({ data }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.BOOKING);
    const id = doc(cRef).id;
    const create = {
      ...data,
      id,
      createAt: serverTimestamp(),
    };

    const dRef = doc(cRef, id);
    setDoc(dRef, create, { merge: true }).then(() => {
      if (callback) {
        callback({
          status: 200,
          data: create,
        });
      }
    });
  };

export const sendMessageToTelegram =
  ({ message }, callback) =>
  (dispatch) => {
    const chatId = CHAT_ID;
    const url = BOT_URL;

    axios
      .post(url, {
        chat_id: chatId,
        text: message,
      })
      .then((response) => {
        // alert("Message sent!");
        console.log(response);
        if (callback) {
          callback({ data: response });
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        alert("Failed to send message.");
        if (callback) {
          callback({ data: error });
        }
      });
  };
// -------------- delete all service in database ---------
export const deleteService = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.SERVICE);
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      data?.forEach((item) => {
        const id = item?.id;
        const dRef = doc(cRef, id);
        deleteDoc(dRef).then(() => {});
      });
    }
  });
};

// -------------- create new service in database ---------
export const createServices =
  ({ services }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.SERVICE);
    services.forEach((service) => {
      const id = doc(cRef).id;

      const data = {
        ...service,
        id,
        description: "",
        createAt: serverTimestamp(),
      };

      const dRef = doc(cRef, id);
      setDoc(dRef, data, { merge: true }).then(() => {});
    });
  };

// -------------- Upload image into storage ---------
export const uploadRequestImage =
  ({ uri }, callback) =>
  (dispatch) => {
    const timestamp = moment().valueOf();
    const href = `images/file_${timestamp}`;
    const storageRef = ref(storage, href);

    if (uri !== "") {
      uploadString(storageRef, uri, "data_url")
        .then(() => {
          getDownloadURL(storageRef).then((url) => {
            console.log({ url });
            if (callback) {
              callback(url);
            }
          });
        })
        .catch((err) => console.log(err));
    } else {
      if (callback) {
        callback("");
      }
    }
  };
