export const generateMessage = ({ data }) => {
  const { services, firstName, lastName, phone, email, fullDate, note } = data;

  const formattedBooking = [];
  let totalPrice = 0;
  let totalServices = 0;
  for (let i = 0; i < services?.length; i++) {
    const service = services[i];
    const formattedService = `
  Service: ${service?.name}
  Quantity: x${service?.amount}
  Total: $${(service?.amount * service?.price).toFixed(2)}
      `;
    formattedBooking.push(formattedService);
    totalPrice += service?.amount * service?.price;
    totalServices += service?.amount;
  }

  const formattedNote = `Note(s): ${note}`;

  const formattedClient = `
-- Client Contact -- 
  Name: ${firstName} ${lastName} 
  Phone: ${phone}
  Email: ${email}
  ${note !== "" ? formattedNote : ""}\n`;

  const message = `
           
      Ms. ${firstName} ${lastName} has just made a booking \n
-- Booking Details --
${formattedBooking.join("")}
Total: ${totalServices} service(s) - $${totalPrice.toFixed(2)}
Booking On: ${fullDate} 
        ${formattedClient}
              
      `;

  return message;
};

export const transformedFormat = (data) => {
  const newData = data?.map((item) => {
    return {
      order: item[0],
      name: item[1],
      price: item[2],
      regularPrice: item[2],
      group: item[3],
      from: item[4],
    };
  });

  return newData;
};

export const convertImageToBase64 = async (imagePath, format = "jpeg") => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imagePath;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Set the MIME type based on the format provided (default is jpeg)
      const mimeType = format === "png" ? "image/png" : "image/jpeg";
      const base64String = canvas.toDataURL(mimeType); // Convert to Base64
      resolve(base64String); // Resolve with the Base64 string
    };

    img.onerror = (err) => {
      console.error("Error loading image:", err);
      reject(err); // Reject if there's an error loading the image
    };
  });
};
