import {
  collection,
  getFirestore,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import { app } from "../../config/config";
import { CREATE_DATA } from "../data/update";
const firestore = getFirestore(app);

// -------------- get data ------------------- //
export const getDatafromCollection = (callback) => (dispatch) => {
  const cRef = collection(firestore, "service");
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      const mapped = data?.map((d) => {
        let createAt = d?.createAt;
        createAt = createAt && moment(createAt?.toDate()).toString();
        return {
          ...d,
          createAt,
        };
      });
      console.log({ mapped });
    }
  });
};

// export const updatePrices = (callback) => {
//   const cRef = collection(firestore, "service");
//   UPDATE_DATA?.forEach((item) => {
//     const id = item?.id;
//     const dRef = doc(cRef, id);
//     const price = item?.price;
//     const regularPrice = item?.regularPrice;
//     const update = {
//       price,
//       regularPrice,
//     };

//     updateDoc(dRef, update)
//       .then(() => {
//         console.log(`Success! ${id}`);
//       })
//       .catch((err) => {
//         console.log({ err });
//       });
//   });
// };

export const createService = () => {
  const cRef = collection(firestore, "service");
  CREATE_DATA?.forEach((item) => {
    const id = doc(cRef).id;
    const create = {
      ...item,
      id,
      createAt: serverTimestamp(),
    };

    const dRef = doc(cRef, id);
    setDoc(dRef, create, { merge: true })
      .then(() => {
        console.log(`Success ${id}`);
      })
      .catch((err) => console.log({ err }));
  });
};
