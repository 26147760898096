import React from "react";
import { Form } from "antd";
// import PhoneInput from "../../../conponent/phone_input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const BookingPhone = (props) => {
  // -------------------------- VAR --------------------------

  const { disabled } = props;
  const countries = ["AU", "NZ"];

  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="phone"
      label="Enter your Mobile"
      rules={[
        {
          required: true,
          message: "Please input phone number",
        },
        {
          whitespace: true,
          message: "Invalid Phone Number",
        },
        {
          min: 11,
          message: "Invalid Phone Number",
        },
        {
          max: 14,
          message: "Invalid Phone Number",
        },
      ]}
    >
      <PhoneInput
        placeholder="Phone number"
        name="phone"
        className="ant-input"
        international
        countryCallingCodeEditable={false}
        disabled={disabled}
        style={{
          opacity: disabled ? 0.5 : 1,
          backgroundColor: disabled ? "#f5f5f5" : "#fff",
        }}
        defaultCountry="NZ"
        // countries={countries}
      />
    </Form.Item>
  );
};

export default BookingPhone;
