import { ExportOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row, Typography } from "antd";
//components
import placeholder from "../../../../asset/img/placeholder.png";
//actions
//selector
//function
//str
const { Text } = Typography;

const HomeLocation = ({ item }) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    window.location.href = window.location.href + "salon/" + item?.slug;
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <div className="home-location-item">
      <img
        src={item?.banner || placeholder}
        alt="logo"
        className={
          item?.banner
            ? "home-location-item-img"
            : "home-location-item-img-placeholder"
        }
      />
      <Row gap={8} vertical className="home-location-item-name">
        <Text strong ellipsis={true} className="location-title">
          {item?.name}
        </Text>
      </Row>
      <Row gap={8} vertical className="home-location-item-text">
        <Col>
          <Text className="location-info" strong>
            Adress:{" "}
          </Text>
          <Text className="location-info">
            {getAddress(item)
              .split("\n")
              .map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
          </Text>
        </Col>
        <Col>
          <Text className="location-info" strong>
            Phone:
          </Text>
          <Text className="location-info"> {item?.phone}</Text>
        </Col>
      </Row>
      <Button
        // type="link"
        // icon={<ExportOutlined />}
        onClick={handleClick}
        className="home-item-enter-btn"
      >
        Select
      </Button>
    </div>
  );
};
export default HomeLocation;

const getAddress = (record) => {
  const address = !!record?.address ? `${record?.address}` : "";
  const centre = !!record?.centre ? `${record?.centre}` : "";
  const city = !!record?.city ? `${record?.city}` : "";
  const label = `${address}\n${centre}\n${city}`;
  return label;
};
